.main-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    background-color: transparent;
}

.card-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    background-color: #fff;
    border-radius: 20px;
    margin: 20px;
    overflow: hidden;
    background-color: transparent;
}

.card-container.shadowed {
    /* display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1388px; */
    background-color: #fff;
    /* border-radius: 20px; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    /* overflow: hidden; */
    height: auto;
}

.card-container.foot {
    height: 85px;
}

.left-card {
    background-color: white;
    flex-basis: 30%;
    height: 100%;
    margin-right: 20px;
    margin-left: 30px;
    border-radius: 20px 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-card .id_status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 13px;
}

.left-card .id_status .id {
    margin-right: 5px;
}

span.status_open {
    color: green !important;
}

span.status_closed {
    color: red !important;
}

.right-card {
    flex-basis: 70%;
    height: 100%;
    border-radius: 0 20px 20px 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.right-card table {
    display: flex;
}

.right-card table tr {
    vertical-align: top;
}

.right-card table th {
    color: #5a606f;
    font-weight: 500;
    padding-bottom: 20px;
    padding-right: 20px;
    text-align: left;
}

.right-card table td {
    padding-bottom: 20px;
}

.bottom-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.bottom-card {
    background-color: #fff;
    flex-basis: 48%;
    min-height: 200px;
    min-width: 200px;
    margin: 10px 0;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.bottom-card.full-width {
    flex-basis: 100%;
}

.bottom-card-content {
    padding: 20px;
}

.text-margin {
    margin: 5px;
}

.schoolPage {
    background-color: #f4f5f8;
    /* font-family: Lato; */
    font-size: 14px;
    margin-bottom: -30px;
}

.schoolPage .schoolPageHead {
    background-color: rgb(221, 124, 60);
    padding-bottom: 80px;
    margin-bottom: -100px;
}

.schoolPage .schoolPageHead .dates {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0 0px;
    color: white;
    font-size: 12px;
}

/* @media (min-width: 851px)
.schoolPage #resume {
    align-items: center;
    min-height: 280px;
}
.schoolPage #resume {
    display: flex;
    position: static;
} */
/* @media (min-width: 851px)
.schoolPage .blocFullSize {
    padding: 32px 48px;
}
@media (min-width: 851px)
.schoolPage .blocFullSize {
    padding-top: 32px;
} */

.schoolPage .resume {
    display: flex;
    align-items: center;
}

.schoolPage .resume .blocFullSize {
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    margin-bottom: 64px;
    position: relative;
    width: 80%;
}

table tbody tr {
    padding: 10px;
}

#cards .bottom-card-content p {
    margin: 0;
}

#cards i.fa-clipboard {
    color: gray;
    cursor: pointer !important;
}

#cards i.fa-clipboard:hover {
    color: rgb(221, 124, 60);
}

#cards i.fa-clipboard.rotate {
    animation: rotate 1s linear infinite;
}

#cards .copied {
    color: rgb(255, 0, 0) !important;
}

#cards .bg {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0 5px 0 5px;
}

#cards .top-middle {
    margin: 0 5px;
}

#cards .top-middle p {
    margin: 10px 0;
    width: 100%;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

table tbody tr td:first-child {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

table tbody tr td:last-child {
    font-weight: bold;
}

@media screen and (min-width: 1480px) {
    div.big-resize {
        max-width: 1200px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

div .col-with-margin {
    box-sizing: border-box;
    width: calc(50% - 10px);
}

@media (max-width: 768px) {
    .col-with-margin {
        margin-right: 0;
        width: 100%;
    }
    #infoBox {
        width: 100%;
        min-width: 320px;
        margin: -10px;
        /* margin-top: 21px;
        margin-bottom: 21px; */
    }
    #otherBox {
        min-width: 350px;
    }
}
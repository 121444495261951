.apply-filter {
    display: flex;
}

.btn-selected {
    background-color: rgb(221, 124, 60) !important;
    color: 2d3273;
}

.btn-unselected {
    background-color: #2d3273 !important;
    color: white !important;
}
.text-orange {
    color: rgb(221, 124, 60) !important;
}

.text-ef {
    color: #2d3273 !important;
}

.btn-ef, .page-item.active .page-link {
    background-color: #2d3273 !important;
    border-color: #2d3273 !important;
    border:#2d3273 !important;
    color: white !important;
}

.btn-outline-ef {
    color: #2d3273 !important;
    border-color: #2d3273 !important;
}

.page-link {
    color: #2d3273 !important;
}
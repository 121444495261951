.container-resultat {
    align-items: center;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px auto;
    position: relative;
    width: 100%;
}

.container-resultat2 {
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
}

.container-resultat .nom-ecole {
    align-items: center;
    background: rgb(221, 124, 60);
    border-radius: 30px;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: center;
    left: -10px;
    min-height: 40px;
    position: absolute;
    text-align: center;
    top: -20px;
    padding-left: 10px;
    padding-right: 10px;
    /* color: white; */
}

.container-resultat .nom-ecole .titre {
    font-size: 16px;
}

.container-resultat .nom-ecole .titre a {
    color: white;
    /* text-decoration: none; */
}

.container-resultat .fiche-ecole {
    display: flex;
    padding: 35px 40px 20px;
    width: 100%;
}

.container-resultat .content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.container-resultat-button {
    align-items: center;
    background-color: #3166be;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    outline: none;
    position: absolute;
    right: -18px;
    top: calc(50% - 20px);
    width: 40px;
}

@media (max-width: 576px) {
    .container-resultat-button {
        top: auto;
        bottom: -18px;
        right: 45%;
    }
}

/* .card {
    position: relative;
}

.title {
    border: solid orange;
    background-color: orange;
    border-radius: 3rem;
    text-align: center;
    color: white
} */

.cursor-pointer {
    cursor: pointer !important;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        width: 100%;
        -webkit-appearance: none;
        background-color: #2d3273;
    }
    
    input[type='range']::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: rgb(221, 124, 60);
      margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
      width: 0px;
      -webkit-appearance: none;
      height: 10px;
      cursor: ew-resize;
      background: #2d3273;
      box-shadow: -80px 0 0 80px rgb(221, 124, 60);
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: rgb(221, 124, 60); 
}
input[type="range"]::-moz-range-track {  
  background-color: #2d3273;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: rgb(221, 124, 60); 
}
input[type="range"]::-ms-fill-upper {  
  background-color: #2d3273;
}

#search-btns button[aria-expanded="true"] {
    background-color: rgb(221, 124, 60) !important;
    color: #2d3273 !important;
}
.logo {
    height: 60%;
    width: 60%;
}

/* .background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 10;
} */

.background {
    position: relative;
    background-image: url('../../../public/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom;
    z-index: 5;
}

/* .blank {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
} */

.search_result .container {
    padding-bottom: 20px;
}

footer {
    background-color: #2d3273;
    bottom: 0;
    width: 100%;
    padding: 3rem 3rem 8rem 3rem;
    z-index: 10;
}

footer a {
    color: white;
    text-decoration: none;
}

footer div.col-md-4:last-child ul li {
    width: 50px;
    height: 50px;
}

footer div.col-md-4:last-child ul li a {
    display: flex;
    color: white;
    text-decoration: none;
    background-color: #042a59;
    border-radius: 3px;
    width: 100%;
    height: 100%;
}

footer div.col-md-4:last-child ul li a i {
    margin: auto;
    font-size: 30px;
}
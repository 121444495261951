.divsearchbar {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
}

.searchbar {
    width: 60%;
}

.loading {
    text-align: center;
}

.card {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    margin: 20px;
    overflow: hidden;
    height: 250px;
}

.card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.sub-card {
    background-color: #e25c0e;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-right: 20px;
}

.button-container {
    margin-left: auto;
}

.rounded-ef {
    display: flex !important;
    border-radius: 25px !important;
    z-index: 99;
}

.rounded-ef i {
    margin: auto;
}

.search_result .content p {
    margin: 0;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.search_result .content span {
    color: black;
    font-weight: bold;
}

.search_result .content p.color-black {
    color: black !important;
}

.container .container-resultat .orange {
    color: #e25c0e;
    text-align: left !important;
    padding: 0 0 20px 35px;
    margin: 0;
}

.nom-ecole h4:hover {
    text-decoration: underline;
    text-decoration-color: white;
}

.small-placeholder::placeholder {
    font-size: 15px;
}

@media (max-width: 1200px) {
    .searchButton {
        margin-top: 3rem;;
    }
}

@media screen and (max-width: 1480px) {
    .searchbar {
        width: 94%;
    }
}
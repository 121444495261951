html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.homeContent {
    position: relative;
    min-height: 100%;
    padding-bottom: 6rem;
    /* hauteur du footer */
}

.spacer {
    height: 6rem;
    /* hauteur du footer */
}

.map {
    position: auto;
}

.section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.text-white {
    text-align: center;
}

#map {
    height: 400px;
    width: 100%;
  }